:host {
  display: block;
}

:host mat-form-field {
  margin-bottom: -1.25em;
  width: 100%;
  input {
    line-height: normal;
  }
}

:host ::ng-deep .mat-form-field-label {
  padding-bottom: 5px;
}

:host ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  padding: 0.75em 0.75em 0 0.75em;
}

:host ::ng-deep .mat-form-field-flex {
    display: inline-flex;
    align-items: baseline;
    box-sizing: border-box;
    width: 100%;
}
